<template>
  <div class="main">
    <img class="img-bg" src="../assets/bg.jpg" />
    <div class="cover-mian">
      <img style="margin-top: 50px; width: 450px" src="../assets/slogan.png" />
      <img
        style="margin-top: 46px; width: 380px"
        src="../assets/apk.png"
        @click="jumpUrl(urlData.apk)"
      />
      <img
        style="margin-top: 10px; width: 380px"
        src="../assets/ipa.png"
        @click="jumpUrl(urlData.ipa)"
      />
      <img
        style="margin-top: 10px; width: 380px"
        src="../assets/tutorial.gif"
        @click="showTip = true"
      />
      <img style="margin-top: 10px; width: 450px" src="../assets/girl.png" />
      <img style="margin-top: 10px; width: 280px" src="../assets/tip.png" />
    </div>
    <div class="tip-main" v-show="showTip">
      <img class="tip-bg" src="../assets/desc.png" />
      <img class="back" src="../assets/back.png" @click="showTip = false" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const showTip = ref(false);
const urlData = ref({});

const loadJsonData = async () => {
  const response = await fetch("/url.json");
  urlData.value = await response.json();
};

const jumpUrl = (url) => {
  window.open(url, "_blank");
};

onMounted(() => {
  loadJsonData();
});
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.main {
  width: 500px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.img-bg {
  width: 500px;
  height: 100vh;
  position: absolute;
}
.tip-main {
  width: 500px;
  height: 100vh;
  position: absolute;
}
.back {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
}

.tip-bg {
  width: 100%;
  height: 100%;
}
.cover-mian {
  width: 500px;
  height: 100vh;
  text-align: center;
  position: absolute;
}
</style>
